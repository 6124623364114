@use 'sass:color';

$background-color: #fafbfd;
$text-color: #1c1c1c;
$link-color: #1b6dd2;
$link-hover: deeppink;
$transition: all 250ms ease-in-out;

$phone: 576px;
$tablet: 768px;
$desktop: 1025px;
$xl-desktop: 1281px;

$shadow-unit: .5rem;
$shadow-size: $shadow-unit * 2;
$shadow-top: $shadow-unit * 6;

@media (min-width: $phone) {  }
@media (min-width: $tablet) {  }
@media (min-width: $desktop) {  }
@media (min-width: $xl-desktop) {  }

@mixin tt-uppercase {
    text-transform: uppercase;
    letter-spacing: .04em;
    font-variant-numeric: lining-nums;
}

@mixin underline {
    text-decoration: underline;
    text-decoration-thickness: from-font;
    text-decoration-skip-ink: auto;
    text-underline-offset: 1px;
}

:root {
    font-family: 'Alegreya Sans', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: $text-color;
    background-color: $background-color;
    scroll-behavior: smooth;
}

// ::-webkit-scrollbar {
//     width: .5rem;
//     &-track { background: color.adjust($background-color, $lightness: -15%); }
//     &-thumb {
//         background: color.adjust($background-color, $lightness: -45%);
//         &:hover { background: $text-color; }
//     }
// }

html,
body {
    scroll-padding-top: 6rem;
}

.content {
    padding-left: 1rem;
    padding-right: 1rem;

    @media (min-width: $tablet) { 
        max-width: $tablet;
        margin-left: auto;
        margin-right: auto;
        padding-right: 1rem;
    }

    @media (min-width: calc($tablet + 2rem)) { 
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: $desktop) { 
        max-width: 960px;
    }

    &.header {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media (min-width: $tablet) { 
            padding-right: 1rem;
        }

        @media (min-width: calc($tablet + 2rem)) {
            padding-right: 0;
        }

        .plate {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (min-width: $tablet) { 
                width: 250px;
            }

        }
    }
    &.home {
        margin-bottom: 6rem;
    }
}

main {
    
    h1 { margin-top: 0; }

    &.home {
        h1 {
            font-size: 4rem;
            margin: 0;
            line-height: 1;
            span {
                background: linear-gradient(to right, #ff8a00, deeppink);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        p {
            font-size: 2rem;
            font-weight: 400;
        }
    }

    &.about {
        p {
            font-size: 1.75rem;
            font-weight: 400;
        }
    }

    &.colophon {
        .side {
            p {
                @media (min-width: $tablet) { 
                    padding-right: 2rem;
                }
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
        .page {
            form {
                @media (min-width: $phone) { 
                    .form-top {
                        display: flex;
                        input[type=text] {
                            margin-right: .5rem;
                        }
                    }
                }
                @media (min-width: $desktop) {
                    max-width: 75%;
                }
            }
            input,
            textarea {
                box-sizing: border-box;
                outline: none;
                display: block;
                width: 100%;
                padding: .5rem;
                border: 1px;
                border-radius: 4px;
                border: 1px solid #ddd;
                margin-bottom: 10px;
                height: 3rem;
                background: white;
                transition: background 250ms ease-in-out;
                &:focus {
                    background: #faffbd;
                }
            }
            textarea {
                min-height: 6rem;
                max-height: 18rem;
                resize: vertical;
                overflow: auto;
            }
            button.send {
                width: 125px;
                border: none;
                border-radius: .25rem;
                height: 3rem;
                background-color: $link-color;
                color: $background-color;
                transition: $transition;
                cursor: pointer;
                margin-top: .5rem;
                &:hover {
                    background-color: color.adjust($link-hover, $lightness: -5%);
                }
                &:active {
                    background-color: color.adjust($link-hover, $lightness: -15%)
                }
            }
        }
    }

    &.fourohfour {
        padding-top: 0;
        height: 300px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        .container {
            margin: 0 auto;
            max-width: 600px;
            text-align: center;
            h1 {
                margin: 0 0 2rem;
                font-size: 7rem;
                line-height: 1;
                letter-spacing: -1px;
                font-weight: 600;
            }
        }
        .container {
            h1 { margin-top: 0; }
            p + p { margin-bottom: 0; }
            .button {
                background-color: $link-color;
                color: $background-color;
                border-radius: .25rem;
                padding: 0 1rem;
                margin-top: 1rem;
                cursor: pointer;
                display: inline-block;
                height: 3rem;
                line-height: 3rem;
                font-weight: normal;
                &:hover {
                    text-decoration: none;
                    background-color: color.adjust($link-hover, $lightness: -5%);
                }
                &:active {
                    background-color: color.adjust($link-hover, $lightness: -15%)
                }
            }
        }
    }
}

hr.separator {
    margin: 2rem auto;
}

footer {
    height: 2rem;
    margin: 4rem auto 2rem;
    display: flex;
    align-items: center;
    font-size: .75rem;
}

.page-loop {
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet) { 
        flex-direction: row;
    }

    + .page-loop {
        margin-top: 3rem;

        @media (min-width: $tablet) { 
            margin-top: 4rem;
        }
    }

    &#skills,
    &#habilidades {
        .page ul {
            margin: 0;
            padding-left: 1rem;

            @media (min-width: $desktop) { 
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
             }

        }
    }

    h2 {
        margin: 0;
        font-size: .9rem;
        font-weight: 600;
        text-transform: capitalize;
        border-color: color.adjust($text-color, $lightness: 5%);
    }
    .side {
        margin-bottom: 1rem;

        @media (min-width: $tablet) { 
            width: 250px;
            flex-shrink: 0;
            margin-bottom: 0;
        }

    }

    .page {
        width: 100%;
        .cv-item {
            h3 {
                margin: 0;
                font-size: .9rem;
                font-weight: 700;
            }

            .timeframe {
                color: #747474;
                font-size: .8rem;
                font-weight: 600;
                @include tt-uppercase;
            }
            
            h6 {
                margin-top: .5rem;
                margin-bottom: .25rem;
                font-weight: 700;
                @include tt-uppercase;
            }
            p { margin: 0; }
            ul {
                margin-top: 0;
                padding-left: 1.5rem;
                margin-bottom: 0;
            }
            + .cv-item { margin-top: 2rem; }
            .location { font-style: italic; }
            
            .institution,
            .company,
            > a {
                font-weight: 600;
            }
        }
    }
}

nav {
    align-items: center;
    // font-weight: 600;

    &#menu{
        display: flex;
        text-align: right;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        align-items: baseline;

        li + li {
            margin-left: .5rem;
            &.lang {
                &.active {
                    a {
                        background-color: $link-color;
                        color: $background-color;
                        border-radius: 2px;
                        padding: 0px 2px;
                        // @include underline;
                    }
                }
            }
        }

    }
    
}

.col {
    >ul {
        padding-left: 1rem;
        margin: 0;

        li {

            + li {
                margin-top: 1rem;
            }
        }
    }
}

.plate {
    a {
        h1 {
            margin: 0;
            font-size: 1.1rem;
            color: $text-color;
            font-weight: 700;
        }
        h2 {
            margin: 0;
            font-size: .8rem;
            color: $link-hover;
            font-weight: 700;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    color: $text-color;
}

.sticky {
    position: sticky;
    top: $shadow-top + ($shadow-size * 3);
    margin-bottom: 1rem;
}

.featured-loop {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;

    @media (min-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    .featured {
        border-radius: 4px;
        overflow: hidden;
        transition: $transition;
        position: relative;
        &:hover {
            box-shadow: .25rem .25rem $shadow-size rgba(#000, .25);
            .overlay {
                opacity: 1;
            }
        }
        .overlay {
            transition: $transition;
            opacity: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            background-color: rgba(#000, .8);
            .title {
                position: absolute;
                bottom: .75rem;
                left: 1rem;
                color: #fff;
            }
        }
        .cover {
            padding-bottom: 56.25%;
            background: no-repeat center/cover;
        }
    }
}


// TEXT STYLE

a {
    color: $link-color;
    transition: $transition;
    text-decoration: none;
    font-weight: bolder;

    &:hover {
        @include underline;
    }

    &.active {
        font-weight: 700;
        color: $link-color;
        @include underline;
    }
}

abbr[title] {
    @include underline;
}

header {
    height: $shadow-top + ($shadow-size * 2);
    position: sticky;
    top: -$shadow-size;
    z-index: 1;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    font-family: 'Montserrat', sans-serif;
    @include tt-uppercase;
    font-size: .7rem;

    &::before,
    &::after {
        content: '';
        display: block;
        height: $shadow-size;
        position: sticky;
    }

    /* SHADOW */
    &::before {
        top: $shadow-top;
        box-shadow: 0 0 (calc($shadow-size / 2)) rgba(#000, .25);
    }

    /* COVER */
    &::after {
        background: linear-gradient($background-color 10%, rgba($background-color, .8) 50%, rgba($background-color, .4) 70%, transparent);
        top: 0;
        z-index: 2;
    }

    .sticky-header {
        background: $background-color;
        position: sticky;
        top: 0;
        margin-top: -$shadow-size;
        z-index: 3;
        height: $shadow-top + $shadow-size;
        display: flex;
        align-items: center;

        @media (min-width: $phone) { 
            padding: 0;
         }

    }

}
